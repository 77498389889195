@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@import "styles.input";
@import "styles.theme";
@import "styles.typography";
@import "styles.icon.scss";

// Understanding layers: https://v2.tailwindcss.com/docs/functions-and-directives#layer

// base styles
@layer base {
    html {
        @apply text-1/2 md:text-normalize;
    }

    body {
        @apply text-gray-dark text-base leading-normal font-body h-full relative font-medium overflow-hidden bg-gray-lightest dark:bg-gray-darkest dark:text-gray-lighter;

        *:focus {
            outline: none;
        }
    }
    a {
        @apply text-blue-dark underline hover:text-blue-darker transition duration-150;
    }
}

// custom components
@layer components {
    .card {
        @apply rounded-lg shadow dark:shadow-none bg-white dark:bg-gray-darkest dark:text-white border-solid dark:border-gray-light dark:border;
    }

    @layer components {
        .custom-table {
            @apply text-sm;
            border-collapse: separate;
            border-spacing: 0 1rem;
            &.has-accordion-rows {
                border-spacing: 0;
                tbody {
                    tr {
                        &.accordion-parent {
                            td {
                                @apply border-t-8 border-white my-20;
                            }
                        }
                        &.accordion-child {
                            td {
                                @apply bg-gray-lighter px-10;
                            }
                            &:not(.last) {
                                td {
                                    &:first-child {
                                        @apply rounded-l-none;
                                    }
                                    &:last-child {
                                        @apply rounded-r-none;
                                    }
                                }
                            }
                            &:first-child {
                                td {
                                    @apply border-t border-gray-light;
                                }
                            }
                            &.last {
                                td {
                                    @apply rounded-t-none;
                                    &:first-child {
                                        @apply rounded-bl;
                                    }
                                    &:last-child {
                                        @apply rounded-br;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            tr {
                &.is-warning {
                    td {
                        @apply bg-orange-lightest;
                    }

                    &:hover {
                        td {
                            @apply bg-orange-lighter bg-opacity-50;
                        }
                    }
                }

                &.has-analytics {
                    td {
                        @apply bg-green-lightest;
                    }

                    &:hover {
                        td {
                            @apply bg-green-lighter bg-opacity-75;
                        }
                    }
                }

                td {
                    @apply transition duration-150 bg-gray-lightest;
                }

                &:hover {
                    td {
                        @apply bg-gray-lighter bg-opacity-75;
                    }
                }
            }

            td:first-child {
                @apply rounded-l;
            }
            td:last-child {
                @apply rounded-r;
            }
        }
    }
}

// loading wheel
@layer components {
    .loader-wrap {
        height: 100%;
        width: 100%;
        display: flex;
    }

    .loader-main {
        border: 12px solid #c8d8e3;
        border-radius: 50%;
        border-top: 12px solid;
        @apply text-blue-darker animate-spin duration-150;
        width: 96px;
        height: 96px;
        // max-width: 40%;
        margin: auto;

        &.is-blue {
            @apply text-blue;
        }
    }

    .loader-sm {
        border: 4px solid #c8d8e3;
        border-radius: 50%;
        border-top: 4px solid;
        @apply text-blue-darker animate-spin duration-150 ;
        width: 30px;
        height: 30px;
        // max-width: 70%; //seems redundant and creates warped spinners
        margin: auto;

        &.is-blue {
            @apply text-blue;
        }
    }
}

// main nav
@layer components {
    .acm-portal-nav {
        @apply flex flex-col flex-grow h-full;
        .acm-portal-subnav {
            &.subnav-absolute {
                @apply absolute top-0 bottom-0 right-0 bg-white dark:bg-gray-darkest py-30 transition duration-200 transform left-64 z-10 border-l border-solid border-gray-lighter animate-slideInLeft;

                .acm-portal-nav-item:not(.is-active):not(:hover) {
                    &.lvl-3 {
                        @apply text-gray dark:text-white;
                    }
                }
            }

            &.is-active {
                @apply translate-x-0;
            }

            .acm-portal-nav-item {
                @apply h-36;

                &.is-active {
                    @apply bg-transparent;
                }
            }
        }

        .acm-portal-nav-item {
            @apply relative min-h-48 font-bold transition duration-150 flex items-center pl-15 cursor-pointer outline-none no-underline text-gray-dark dark:text-white dark:text-opacity-75;

            &.is-active.no-bar {
                &::after {
                    @apply hidden;
                }
            }

            &::after {
                content: "";
                @apply absolute;
                @apply right-0;
                @apply top-0;
                @apply w-4;
                @apply h-full;
                @apply bg-transparent;
            }

            &:hover {
                @apply no-underline bg-blue-lighter bg-opacity-25;
            }

            &.is-active {
                @apply text-blue-dark dark:text-blue-light no-underline bg-blue-lighter bg-opacity-25;

                &::after {
                    @apply bg-blue-dark dark:bg-blue-light;
                }
            }
        }

        .acm-portal-nav-label {
            @apply text-xs uppercase tracking-wide;
        }
    }
}

// component base styles
@layer components {
    app-root {
        @apply w-full h-full fixed z-10 flex;

        .acm-portal-body {
            > * {
                &:not(router-outlet) {
                    @apply flex flex-col;
                    // &:not(app-product-rd) {
                    //     @apply p-10;

                    //     @media (min-width: theme("screens.md")) {
                    //         @apply p-20;
                    //     }
                    // }
                }
            }
        }
    }

    app-portal {
        @apply w-full h-full fixed z-10 flex;

        .acm-portal-body {
            > * {
                &:not(router-outlet) {
                    @apply flex flex-col;
                    // &:not(app-product-rd) {
                    //     @apply p-10;

                    //     @media (min-width: theme("screens.md")) {
                    //         @apply p-20;
                    //     }
                    // }
                }
            }
        }
    }

    app-accounts-page,
    app-files-messages-page,
    app-request-tracker-page {
        @apply h-full;
    }

    app-accounts {
        .custom-table tr {
            &:hover td {
                @apply bg-gray-lightest bg-opacity-100;
            }
        }
    }

    app-investment-list {
        @apply h-auto lg:h-full;
    }

    app-account-update-main-modal {
        app-contribution-request,
        app-distribution-request,
        app-reallocation-request,
        app-close-account-request {
            @apply flex flex-col h-full;
        }
    }
    app-players-page {
        @apply h-full w-full  flex flex-col flex-grow items-center overflow-auto bg-gray-lightest;
    }

    app-teams-page {
        @apply h-full w-full  flex flex-col flex-grow items-center overflow-auto bg-gray-lightest;
    }
}

// custom components
@layer components {
    .divider-horizontal {
        @apply h-px block bg-gray-lighter my-10;
    }
}

@layer components {
    .whitespace-pre-line {
        white-space: pre-line;
    }

    .whitespace-pre-wrap {
        white-space: pre-wrap;
    }

    .whitespace-pre {
        white-space: pre;
    }

    .overflow-wrap-text {
        overflow-wrap: break-word;
    }
}

@layer components {
    app-first-mfa-setup-page,
    app-first-mfa-verification-page,
    app-login-page,
    app-password-reset-requested-page,
    app-create-new-password-page,
    app-mfa-verification-page {
        @apply w-full h-full;
    }
}

@layer components {
    app-planning-desk-request {
        @apply block h-full;
    }
}

::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #c1c3c9;
    border-radius: 9999px;
}
